import React from 'react'
import { hydrate, render } from 'react-dom'
import Loadable from 'react-loadable'
import LoadingComponent from './components/partials/LoadingComponent'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root')

const AppAsync = Loadable({
  loader: () => import('./App'),
  loading: LoadingComponent
})

if (rootElement.hasChildNodes()) {
  hydrate(<AppAsync/>, rootElement)
} else {
  render(<AppAsync/>, rootElement)
}

serviceWorker.unregister()
