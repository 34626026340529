import React from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import { ReactComponent as Spinner } from '../../images/loading_spinner.svg'

const CenteredRow = ({ children }) => (
  <Row className='h-100 align-items-center'>{children}</Row>
)

const LoadingComponent = ({ isLoading, timedOut, pastDelay, error, retry }) => {
  if (isLoading) {
    if (timedOut) {
      return (
        <CenteredRow>
          <Button onClick={retry}>Re-intentar</Button>
        </CenteredRow>
      )
    }

    if (pastDelay) {
      return (
        <CenteredRow>
          <Spinner/>
        </CenteredRow>
      )
    }

    return null
  }

  if (error) {
    console.warn(error)

    return (
      <CenteredRow>
        Ha ocurrido un error. <Button onClick={retry}>Intentar de nuevo</Button>
      </CenteredRow>
    )
  }

  return null
}

export default LoadingComponent
